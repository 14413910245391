import { render, staticRenderFns } from "./AdminSkillCategoryMaster.vue?vue&type=template&id=635d2885&scoped=true"
import script from "./AdminSkillCategoryMaster.vue?vue&type=script&lang=js"
export * from "./AdminSkillCategoryMaster.vue?vue&type=script&lang=js"
import style0 from "./AdminSkillCategoryMaster.vue?vue&type=style&index=0&id=635d2885&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635d2885",
  null
  
)

export default component.exports